/**
 * Extended attribute classes for Flex Layouts
 * DBS>Interactive
 */


// Background colors and images

.bg-img {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	overflow: hidden;
	position: relative;

	// Background image wrapper/tag styles
	& > .image-wrapper {
		height: 100%;
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;

		& img {
			@include object-fit(cover, center);
		}
	}

	// Adds a bit of breathing room above and below the layout container
	& > .contain {
		@include vertical-padding;
	}

	// Brightens the text when a background image is present
	h1, h2, h3, h4, h5, h6, p {
		color: $white;
	}
}

.bg-dark {
	background-color: $grayDarkest;

	&, h1, h2, h3, h4, h5, h6, a {
		color: $white;
	}

	a:hover,
	a:focus {color: $white;}

	&.layout:not(.page-header) {
		@include vertical-padding;
	}
}

.bg-dark-slant {
	@extend .bg-dark;
	padding-top: 4.5rem !important;
	position: relative;

	&::before {
		background: $white;
		clip-path: polygon(0 0, 100% 0, 100% 0, 0% 100%);
		content: '';
		height: 4.5rem;
		position: absolute;
			top: -1px;
			left: 0;
		width: 100%;
	}

	@include media($screen-sm) {
		padding-top: 6rem !important;

		&::before {height: 6rem;}
	}
}

.bg-blue {
    @extend .bg-dark;
    background: $blueDarker;
    
    .services__list-item.active {
        background: #050C1A;
        border-color: #D70B0B;
        color: $white;
        
        &::before {
            border-color: #D70B0B;
        }
    }
    
    .services__right {
        background: #050C1A;
        border-left: 4px solid #D70B0B;
        padding-right: #{$spacing * 2};
    }
}

.bg-gradient {
	@extend .bg-dark;
	background: linear-gradient(136.86deg, #020A19 0%, #081F3F 100%);
}

.bg-gradient-darkblue {
    @extend .bg-dark;
    background: linear-gradient(to right, #2f3543, #0a0c0f);
}

.bg-light {
	background-color: $grayLighter;

	&.layout:not(.page-header) {
		@include vertical-padding;
	}
}

.bg-white {
	background-color: $white;

	&, h1, h2, h3, h4, h5, h6, a {
		color: $textColor;
	}
}

.bg-teal {
	background-color: $teal;
}

.teal-accent-left,
.teal-accent-right,
.teal-accent-slant-right,
.teal-accent-slant-left {
	@include vertical-padding;
	position: relative;

	&::before {
		background-color: $teal;
		content: '';
		position: absolute;
			top: 0;
		height: 100%;
		width: 33.33%;
		z-index: -1;
	}
}

.teal-accent-slant-left,
.teal-accent-slant-right {
	&::before {
		height: 70%;
	}
}

.teal-accent-slant-left::before {clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);}
.teal-accent-slant-right::before {clip-path: polygon(0 0, 100% 0, 100% 100%, 0 50%);}


.teal-accent-left::before,
.teal-accent-slant-left::before {left: 0;}

.teal-accent-right::before,
.teal-accent-slant-right::before {right: 0;}



// Adds an overlay to the background image to allow content to pop
.bg-overlay {
	.cell, .contain {
		position: relative;
		z-index: 1;
	}

	&:after {
		background: rgba($black, 0.5);
		content: '';
		height: 100%;
		position: absolute;
			left: 0;
			top: 0;
		width: 100%;
	}
}


// Thumbnail cell image styles
.thumbnail {
	.column {
		display: flex;
			align-items: center;

		// The column gets the padding for this style.
		padding: 1em;
		@include media ($screen-sm) {
			padding: 0 $spacing $spacing;
		}

		// Give margin-right to the first item (image or content)
		& > :first-child { margin-right: $spacing; }
	}

	.cell {
		flex: 1;
		padding: 0; // The column gets the padding for this style.
	}

	.image-wrapper {
		height: 0;
		padding-top: 5em;
		overflow: hidden;
		position: relative;
		width: 6em;

		img {
			height: 100%;
			max-width: initial;
			position: absolute;
				left: 50%;
				top: 50%;
			transform: translate(-50%,-50%);
			width: auto;
		}
	}
}


// Image Card cell layout
.image-card {
	.image-wrapper {
		margin: 0 $spacing $spacing $spacing;
		margin-right: $spacing;
		max-height: 20em;
		overflow: hidden;

		img {
			height: 100%;
		}
	}
}

.vertical-padding,
.v-pad {
	@include vertical-padding;
}

// Used mostly for dark background layouts where this spacing will mimic the 
// default layout margin spacing set by the default-margin mixin.
.v-pad-top {padding-top: $vSpacing*2;}
.v-pad-bottom {padding-bottom: $vSpacing*2;}

@include media($screen-sm) {
	.v-pad-top {padding-top: $vSpacing*3;}
	.v-pad-bottom {padding-bottom: $vSpacing*3;}
}

.no-bottom-margin {
	margin-bottom: 0 !important;
}

.no-top-margin {
	margin-top: 0 !important;
}

.no-bottom-padding {
	padding-bottom: 0 !important;
}

.no-top-padding {
	padding-top: 0 !important;
}

.no-padding {
	padding-bottom: 0 !important;
	padding-top: 0 !important;
}

/**
 * Dark BG w/Huge Chevron
 */
.chevron-bg {
	@extend .bg-dark;
	background: linear-gradient(135deg, #1B2E4D 0%, #020A19 100%);
	position: relative;

	.contain {
		position: relative;
		z-index: 2;
	}

	&:after {
		background: url('#{$libraryPath}/icons/chevron_large.svg') no-repeat;
			background-position: -50vw 80%;
			background-size: auto 150%;
		content: '';
		height: 100%;
		opacity: .19;
		position: absolute;
			top: 0;
			left: 0;
		width: 100%;
		z-index: 0;

		@include media($screen-sm) {
			background-size: auto 200%;
			background-position: -20vw 80%;
		}
	}
}

// Horizontal list with items separated by vertical lines
.horizontal-list {
	margin: 0;
	padding: 0;

	li {
		display: inline-block;
		padding: .75rem 1rem .75rem 1rem;

		@include media($screen-sm) {
			border-right: 1px solid rgba($white, .3);
			margin-bottom: 1.5rem;
			margin-right: 1rem;
			padding: .5rem 1rem .75rem 0;

			&:last-child {
				border: none;
				margin-bottom: 0;
				margin-right: 0;
				padding-right: 0;
			}
		}

		a {
			padding-bottom: .25rem;
			text-decoration: none;
		}
	}
}