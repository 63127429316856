/**
 * DBS>Interactive
 * General Theme Type classes
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend %button-type;
 */


/**
 * Default Typography
 */
html {
	@extend %default-type;
	font-size: $bodyFontSize;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
	scroll-behavior: smooth;

	@media (min-width: $screen-sm) {
		font-size: $bodyFontSize * 0.95;
	}
	@media (min-width: $screen-md) {
		font-size: $bodyFontSize;
	}
}

body {
	@extend %default-type;
	font-size: $bodyFontSize;
	font-weight: 400;
	line-height: $bodyLineHeight;
}


nav {
	@extend %header-type;
}


h1, h2, h3, h4, h5, h6 {
	@extend %header-type;
	margin-top: 0;
	margin-bottom: $vSpacing * 1;
	text-rendering: optimizeLegibility;

	a { text-decoration: none; }
}

strong {
    font-weight: 700;
}

.h1, h1 { @include font-scale( 5, $headingFontSize); }
.h2, h2 { @include font-scale( 4, $headingFontSize); }
.h3, h3 { @include font-scale( 3, $headingFontSize); }
.h4, h4 { @include font-scale( 2, $headingFontSize); }
.h5, h5 { @include font-scale( 1, $headingFontSize); }
.h6, h6 { @include font-scale(0, $headingFontSize); }


a {
	color: $linkColor;
	font-weight: bold;
	text-decoration: underline;
	transition: color ease-in-out 0.25s;
	&:hover, &:focus, &:active { color: darken($linkColor, 10%); text-decoration: none;}
}

.bg-dark a {
	color: $white;
	&:hover, &:focus, &:active { color: darken($white, 10%); }
}


p {
	margin-top: 0;
	margin-bottom: $spacing;
}

.font_small,
small { font-size: 0.707em; }

.intro-text,
.bigger-text {
	@include media($screen-sm) {font-size: 1.125rem;}
}

.font-size-xs, .fs-xs { font-size: .875rem; }
.font-size-sm, .fs-sm { font-size: .9375rem; }
.font-size-md, .fs-md { font-size: 1rem; }
.font-size-lg, .fs-lg { font-size: 1.125rem; }
.font-size-xl, .fs-xl { font-size: 1.25rem; }
.font-size-xxl, .fs-xxl { font-size: 1.375rem; }
.font-size-xxxl, .fs-xxxl { font-size: 1.5rem; }

.text-center {text-align: center;}

.caps {text-transform: uppercase;}

.spaced {letter-spacing: .25rem;}

.eyebrow {
	@extend .spaced;
	font-size: 1rem;
	font-weight: 900;
	line-height: 1.5;
	text-transform: uppercase;
}

/**
 * Type Colors
 */
 .teal {color: $teal !important;}
 .teal-dark {color: $tealDarker !important;}
 .blue {color: $blue !important;}
 .dark-blue {color: $blueDarker !important;}
 .light-blue {color: $blueLighter !important;}
 .coral {color: $coral !important;}
 
 .black {color: $black !important;}
 .black-lighter {color: $blackLighter !important};
 .white {color: $white !important;}
 
 /**
  * Font Weight Classes
  */
 .extra-thin {font-weight: 100;}
 .thin {font-weight: 300;}
 .normal {font-weight: 400;}
 .semi-bold {font-weight: 600;}
 .bold {font-weight: 700;}
 .extra-bold {font-weight: 900;}

// Type Style Classes
.caps {text-transform: uppercase;}
.underline {text-decoration: underline;}

// Alignment Classes
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

// Heading with a horizontal line "behind" it
.lined-heading {
    font-size: 1.125rem;
    font-weight: 700;
    position: relative;
    text-align: center;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 40%; // Adjust width as needed
        height: 1px; // Adjust height of the horizontal rule
        background-color: #ccc; // Adjust color as needed
    }

    &::before {
        left: 0;
        transform: translateY(-50%);
    }

    &::after {
        right: 0;
        transform: translateY(-50%);
    }
}

/**
 * Animated link
 */
 .line-link {
	display: inline-block;
	overflow: hidden;
	padding-bottom: .25rem;
	position: relative;

	&:before,
	&:after {
		content: '';
		height: 1px;
		position: absolute;
			bottom: 0;
			left: 0;
		transition: .25s ease-in-out;
		width: 100%;
	}

	&:after {background: $white;}

	&:before {
		background: linear-gradient(to right, $teal, $coral);
		transform: translate3d(-101%, 0, 0);
		z-index: 2;
	}

	&:hover,
	&:focus {
		&:before {transform: translate3d(0,0,0);}
	}
}