/**
 * Header styles
 * DBS>Interactive
 *
 * 		site-header - the page's main header element
 * 			site-header__branding - the company logo
 * 			site-header__navigation - the site navigation
 * 				NOTE: Put navigation styles in _navigation.scss.
 *
 * ************************************************************************
 * This file is for the general header/branding styles. For specific
 * menu/navigation files, use _navigation.scss.
 * ************************************************************************
 */

 .site-header {
	background: linear-gradient(90deg, #1B2E4D 0%, #020A19 100%);
	border-bottom: 1px solid rgba($white, .3);
	position: relative;
	z-index: 10;

	h1 { // Offset bottom margin for exact centering
		padding-top: 1rem;
	}

	&__container {
		display: flex;
			align-items: center;
			justify-content: space-between;
		padding: 0 $spacing;
	}

	@include media($mobileNavBPMax, 'max') {
		
	}

	/**
	 * Company logo in site header
	 */
	&__branding {
		a {
			display: block;
			line-height: 0;
			margin-left: -.25em; // Move the left edge of the logo all the way to the edge of the container
			max-width: 12rem;
			padding: .25em;
			width: calc(7rem + 10vw);
		}
	}
}

/**
 * Hamburger Icon
 */
.menu-toggle {
	@include size(4rem);
	background-color: $teal;
	border: 0;
	display: block;
	margin-right: -$spacing; // Move the right edge of the icon all the way to the edge of the container
	padding: 0.5em;

	&__bar {
		background: $blueDarkest;
		display: block;
		height: 2px;
		margin: 0 auto 7px;
		opacity: 1;
		transition: transform 0.25s, width 0.25s;
		width: 2rem;

		&:last-child { margin-bottom: 0; }
	}

	&.open > .menu-toggle__bar {
		&:nth-child(1) { transform: translate(0, 8px) rotate(45deg); }
		&:nth-child(2) { width: 0; }
		&:nth-child(3) { transform: translate(0, -10px) rotate(-45deg); }
	}

	// Don't display in desktop
	@include media($mobileNavBP) {
		display: none;
	}
}

/**
 * Phone Icon
 */
.site-header__phone {
	@include size(4rem);
	border-left: 1px solid #3F5982;
	margin-left: auto;
	position: relative;

	svg {
		@include absolute-center;
		height: auto;
		width: 1.5rem;
	}
}