/**
 * Slate Navigation Styles
 * DBS>Interactive
 *
 * ************************************************************************
 * This file is for the navigation menu in the header - both the mobile and
 * desktop menu. Keep header/branding specific styles in _header.scss.
 * ************************************************************************
 *
 * 		menu-toggle - the hamburger menu button
 * 			open - the class given when the toggle button is in the open state
 * 			menu-toggle__bar - the individual hamburger bars
 * 		submenu-toggle - a submenu toggle button
 * 			open - the class given when the toggle button is in the open state
 *
 * 		site-header__navigation - the header container for all site header
 * 			navigation.
 *
 * 			secondary-menu - the (nav element) container for the secondary menu
 * 			main-menu - the (nav element) container for the main menu
 *
 * 		menu - the container class for a menu ( ul element )
 * 			open - the class added to a ( mobile ) menu that is opened
 *
 * 			menu__item - a menu item ( li element )
 * 				menu__item--active - the active item ( current page )
 * 				menu__item--parent - an item that has a submenu
 *
 * 			menu__submenu - a submenu element ( ul element )
 * 				menu__submenu--l1 - a first level submenu
 * 				menu__submenu--l2 - etc...
 *
 */

/**
 * Submenu Toggle Icon
 */
 .submenu-toggle {
	background-color: transparent;
	border: 0;
	height: 4em;
	margin: 0;
	padding: 0;
	position: absolute;
		top:  0;
		right: 0;
	transition: transform 0.25s ease-in-out;
	transform: none;
	width: 3em;

	&__arrow {
		color: $blueDarkest;
		display: inline-block;
		font-size: 1.5rem;
		transition: transform .25s ease-in-out;
	}

	&.open {
		.submenu-toggle__arrow {
			transform: rotate(180deg);
		}
	}

	// Desktop styles
	@include media($mobileNavBP) {
		height: 2em;
		right: -.5rem;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0;
		width: 1rem;

		&:focus {
			opacity: 1;
		}

		&__arrow {
			color: $teal;
			height: 100%;
		}

		.menu__submenu > .menu__item > & {
			opacity: 1;
			right: 0;
			transform: translateY(-50%) rotate(-90deg);
		}
	}
}

.subitem .submenu-toggle {
	@include media($mobileNavBP) {
		position: absolute;
			top: 50%;
	}
}

/**
 * General Menu Styles
 *
 * These styles apply to both the mobile and the desktop menu.
 */
.site-header__navigation > nav {
	z-index: 100;
	// Resets
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: $white;
		display: block;
		font-size: 1em;
		font-weight: 600;
		letter-spacing: 1px;
		text-decoration: none;

		&:hover, &:active, &:focus {
			color: $teal;
		}
	}

	// Contact Us Button
	.contact {
		margin-right: -$spacing; // Pull it flush to screen edge
		padding: 0;

		a {
			@extend %button-type;
			background-color: $teal;
			border: 1px solid $teal;
			color: $blueDarkest;
			display: inline-block;
			font-weight: 900;
			letter-spacing: 2px;
			padding: 1.5em 2.5em;
			text-align: center;
			text-decoration: none;
			transition: all .25s ease;
			text-transform: uppercase;
			-webkit-appearance: nowrap;
			white-space: nowrap;

			// Swap the colors on hover
			&:hover, &:active, &:focus {
				background-color: $blueDarkest;
				border-color: $teal;
				color: $teal;
			}
			font-size: 1.15em;
			letter-spacing: 5px;
			padding: 1.5em 2em;
		}

		@include media($mobileNavBPMax, 'max') {
			width: 100%;

			a {
				background: $blueDarkest;
				color: $white;
				display: block;
				margin: $spacing;
				text-align: center;
			}
		}
	}
}

/**
 * Mobile Menu Styles
 */
@include media($mobileNavBPMax, max) {

	.site-header__navigation {
		background-color: $teal;
		max-height: 0;
		overflow-x: auto; // Should probably use javascript animation...
		position: absolute;
			top: 100%;
			right: 0;
			left: 0;
		transition: max-height .2s ease-in-out,
					opacity .2s ease-in-out,
					visibility .2s ease-in-out;
		visibility: hidden;

		/**
		 * Styles for when the mobile menu is opened.
		 */
		&.open {
			max-height: 100vh;
			visibility: visible;
		}

		> nav a {
			color: $blueDarkest;

			&:hover,
			&:focus {color: $blueDarkest;}
		}
	}

	.menu {

		/**
		 * Individual menu item
		 */
		&__item {
			border-bottom: 1px solid rgba($blueDarkest, .2);
			display: block;
			position: relative;

			&:last-child { border: none; }

			a {
				padding: 1.25em $spacing;
			}

			&.toplevel {
				a {
					font-weight: 900;
					letter-spacing: 5px;
					text-transform: uppercase;
				}
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $teal;
			max-height: 0;
			overflow: hidden;

			/**
			 * Submenus when hovering parent
			 */
			.open > & {
				max-height: none;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				margin: 0 $spacing;

				a {
					font-weight: 600;
					letter-spacing: normal;
					padding: 1.25rem 0;
					text-transform: none;

					&:after {
						content: url('#{$libraryPath}/icons/arrow.svg');
						display: inline-block;
						height: 1rem;
						margin-left: .5rem;
						opacity: .6;
						transform: translate(0, 2px); 
						transition: .25s;
						width: 1rem;
					}

					&:focus,
					&:hover { &:after { transform: translate(.5rem, 2px); } }
				}
			}

			/**
			 * Sub-Submenus
			 */
			& & {
				// TODO: Placeholder; remove if not used
				a {
					background-color: $gray;
				}
			}
		}
	}
}

/**
 * Desktop Menu Styles
 */
@include media($mobileNavBP) {
	.site-header__navigation {width: 100%;}

	.main-menu {
		display: flex;
			justify-content: flex-end;
		flex: 1;
	}

	.menu {
		display: flex;
		    align-items: center;
			flex-direction: row;
			justify-content: center;
		position: relative;
		width: 100%;

		/**
		 * Individual menu item
		 */
		&__item {
			display: flex;
				align-items: center;
			height: 100%;
			margin: 0 .5em;
			padding: .1em;
			position: relative;

			&:first-child {margin-left: auto;}
			&:last-child {margin-left: auto;}

			a {
				padding: .333em calc(.25em + .5vw);
				width: 100%;
			}
		}

		&__item--parent {
			.submenu-toggle svg {transition: .25s transform;}

			&:hover,
			&:focus {
				&>.submenu-toggle svg {@include media($mobileNavBP) {transform: rotate(180deg);}}
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $teal;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
			opacity: 0;
			position: absolute;
			   top: 100%;
			   left: 50%;
			transition: opacity .32s ease-in-out,
						visibility .32s ease-in-out;
			transform: translateX(-50%);
			visibility: hidden;
			width: 18.5em;

			&:after {
				@include size(.75rem);
				background: $teal;
				content: '';
				position: absolute;
					top: -.25rem;
					left: 50%;
				transform: translateX(-50%) rotate(45deg);
			}

			/**
			 * Submenus with open class and when hovering parent
			 */
			.open > &,
			.menu__item--parent:hover > & {
				opacity: 1;
				transition: opacity .16s ease-in-out,
							visibility .16s ease-in-out;
				visibility: visible;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				border-bottom: 1px solid rgba(5, 12, 26, .2);
				margin: 0 1rem;

				&:last-child {border: none;}

				a {
					color: $blueDarkest;
					font-weight: 600;
					padding: .75em 0;
					text-transform: none;

					&:after {
						background: url('#{$libraryPath}/icons/arrow.svg') no-repeat center;;
							background-size: contain;
						content: '';
						height: .75rem;
						opacity: .8;
						position: absolute;
							top: 50%;
							right: .5rem;
						transform: translate(0,-50%);
						transition: transform .25s ease-in-out;
						width: .75rem;
					}

					&:hover,
					&:focus {
						color: $blueDarkest;
						font-weight: 700;

						&:after {transform: translate(.75em, -50%);}
					}
				}
			}

			/**
			 * Sub-Submenus
			 */
			& & {
				// TODO: Placeholder; remove if not used
				border-left: 1px solid $grayDarkest;
				left: 100%;
				top: 0;
				transform: none;
			}
		}
	}
}
