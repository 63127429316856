/**
 * Header Search Form
 */
 .search-form {

	&__form {width: 100%;}

	&__container {
		display: block;
		position: relative;
		width: 100%;
	}

	&__field {
		background: $white;
		display: block;
		height: 100%;
		width: 100%;

		&::placeholder {color: $blueDarkest;}
	}

	&__button--search {
		background-color: transparent;
		background: url('/icons/search.svg') no-repeat center;
			background-repeat: no-repeat;
			background-position: right;
			background-size: 1.5em;
		border: none;
		display: block;
		height: 100%;
		transition: .5s;
		width: 2.5rem;
		z-index: 20;

		&:focus,
		&:hover {
			background-color: transparent;
			border: 0;
		}
	}

	&__button--search {
		background-position: center;
		position: absolute;
			top: 50%;
			right: 0;
		transform: translateY(-50%);
	}
}


//After clicked
.search-form--active {
	.search-form__form {height: 100%;}

	.search-form__button--open {
		display: none;
	}

	.search-form__button--close {
		display: block;
	}

	@include media ($mobileNavBP) {	
		.search-form__container {
			display: block;
			width: 100%;
		}
	}
}