/**
 * General styles and helpful classes
 * DBS>Interactive
 */

*,
*:after,
*:before,
input[type=search] {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

// Tap highlight color
* { -webkit-tap-highlight-color: rgba($primaryColor, .3); }

// Text/image selection highlight color
::selection { background: rgba($primaryColor, .3); }

body {
		// Focus outline for site
	:focus {
		outline: none;
		box-shadow: 0 0 0 1px #000, 0 0 0 2px #fff;
	}

	.site-content:after { // for search purposes
		content: '';
		background-color: rgba(0,0,0,.7);
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity .25s linear;
		z-index: 1;
		visibility: hidden;
	}
	&.opened-menu,
	&.accessibility-menu-opened {
		.site-content:after {
			opacity: 1;
			transition: opacity .25s linear;
			visibility: visible;
		}
	}
}


// Remove theme-specific focus color from the CMS
body.wp-admin :focus {
		box-shadow: none;
}
html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

address, ol, p, ul {
	margin: 0 0 $spacing;
}

ol ol, ol ul, ul ol, ul ul {
	margin: 0 0 $spacing;
}

hr {
	border: none;
	border-top: 1px solid $grayLighter;
	display: block;
	height: 1px;
	margin: $spacing 0;
	padding: 0;
}

blockquote {
	border-left: 1px solid $blueLighter;
	margin: 2rem auto;
	overflow: hidden;
	padding: .75rem $spacing;
	position: relative;

	@include media($screen-sm) {
		font-size: 1.25rem;
	}

	img.alignnone {
		border-radius: 100%;
		display: block;
		margin: 0 auto;
		overflow: hidden;
		text-align: center;

		@media screen and (min-width:$mobileNavBP) {
			display: inline-block;
			float: left;
			margin-right: 7%;
			max-height: 8em;
			max-width: 8em;
		}
	}

	small {
		@include font-scale(-1, $bodyFontSize);
		display: inline-block;
		margin: 0 0 0.8em 1.5em;
		&:before { content: '\2014 \00A0'; }
	}

	cite { font-weight: bold; }
	cite a { font-weight: normal; }
}

.wp-caption {
	margin-bottom: 3rem !important;
	max-width: 100%;

	img {display: block;}
}

figcaption {
	border-bottom: 2px solid #DFE2E5;
	margin-bottom: 3rem !important;
	max-width: 100%;
	padding: 1rem 0;

	span {
		color: #545B61 !important;
		font-family: $bodyFont !important;
		font-size: 1rem !important;
		font-style: italic !important;
	}
}

figure img {
	width: 100%;
}

.wp-caption-text {
	border-bottom: 2px solid #DFE2E5;
	color: #545B61;
	font-style: italic;
	padding: 1rem 0;
}

p:last-child {
	margin-bottom: 0em;
}

dl {
	margin: 0 0 $spacing * .5;
	dt {
		font-weight: bold;
		margin: 0 0 $spacing * .5;
		padding-right: 1em;
	}

	dd {
		margin-bottom: $spacing * .5;
		margin-left: 2em;
	}
}


mark { background-color: $grayLighter; }

code, tt {
	@include font-scale(-1, $bodyFontSize);
	background: $grayLighter;
	border-radius: 2px;
	border: 1px solid darken($gray,10%);
	font-family: Inconsolata, monospace, sans-serif;
	padding: 1px 3px;
	white-space: pre;
}

pre {
	@include font-scale(-1, $bodyFontSize);
	background: $grayLighter;
	border-radius: 3px;
	border: 1px solid darken($grayLighter,10%);
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	font-family: Inconsolata, monospace, sans-serif;
	margin: 0 0 $spacing;
	overflow: auto;
	padding: 1em 1.25em;
	white-space: pre;
	width: 100%;
}

pre code, tt {
	background: transparent;
	border: none;
	font-size: inherit;
	padding: 0;
	white-space: -moz-pre-wrap;
	white-space: pre-wrap;
}


img, svg {
	height: auto;
	max-width: 100%;
}

svg {
	width: 100%; // Stabalize svg width if undefined
}

li > ol,
li > ul {
	margin-bottom: 0;
}



/**
 * Helpful Re-usable Classes
 */

.site-logo {
	fill: $primaryColor;
	transition: fill ease-in-out 0.25s;
	&:hover {
		fill: lighten($primaryColor, 10%);
	}
}

.desktop-hidden {
	@include media ($screen-sm) {
		display: none !important;
	}
}

.mobile-hidden {
	@include media ($screen-sm-max, 'max') {
		display: none !important;
	}
}

// Since the mobile nav breakpoint is subject to change, these are sometimes useful.
.mobile-nav-visible {
	@include media($mobileNavBP) {display: none !important;}
}

.mobile-nav-hidden {
	@include media($mobileNavBPMax, 'max') {display: none !important;}
}

.lazy-load {
	max-width: 100%;
}

.linkclicker,
button,
input[type=submit] {
	cursor:pointer;
}

.alignright {
	float: right;
	margin-left: $spacing;
	margin-bottom: $spacing;
}

.alignleft {
	float: left;
	margin-right: $spacing;
	margin-bottom: $spacing;
}

@include media($screen-sm) {
	.alignright,
	.alignleft {
		&.size-large {
			max-width: 50%;
		}
	}

	.image-style-align-right {
		float: right;
		margin-bottom: $spacing !important;
		margin-left: $spacing !important;
		width: 50%;
	}

	.image-style-align-left {
		float: left;
		margin-bottom: $spacing !important;
		margin-right: $spacing !important;
		width: 50%;
	}
}

.aligncenter, .center {
	margin: $spacing auto;
	display: block;
}

.alignnone {
	margin: 0;
}

.left {
	float: left;
}

.right {
	float: right;
}

.hidden,
.hide { display: none; }

.assistive,
.hide-text,
.screen-reader-text {
	@include size(1px);
	border    : 0;
	clip      : rect(0 0 0 0);
	margin    : -1px;
	overflow  : hidden;
	padding   : 0;
	position  : absolute;
	background: $white;
	color     : $black;

	&.show-on-focus:focus {
		background-color: rgba(0,0,0,.9);
		border-radius: 1em;
		box-shadow: 0 0 3px 5px;
		color: $white;
		clip: unset;
		display: inline-block;
		height: auto;
		left: 50%;
		padding: 6em;
		position: fixed;
		top: 50%;
		transform: translate(-50%,-50%);
		transition: background-color .25s linear;
		width: auto;
		z-index: 99999;
	}
}

.hide-text { text-indent: -9999px; }

.two-column-list {
	@include media($screen-sm) {
		columns: 2;
		column-gap: 3rem;

		li,
		&__item {break-inside: avoid-column;}
	}
}

.embed-container {
	height: 0 !important;
	max-width: 100%;
	overflow: hidden;
	padding-bottom: 56.25%;
	position: relative;
	width: 100% !important;

	iframe {
		@include size(100%);
		position: absolute;
			top: 0;
			left: 0;
	}
}

.two-column-list {
	@include media($screen-sm) {
		columns: 2;
		column-gap: 3rem;

		li,
		&__item {
			break-inside: avoid-column;
			position: relative;
			z-index: 2; // Prevents overflow clipping issue on hover transforms
		}
	}
}

.link-list {
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;

	&:after { // Covers the last row's bottom borders without having to consider number of children/odd-even children
		background: $white;
		content: '';
		height: 1px;
		position: absolute;
			bottom: 0;
			left: 0;
		width: 100%;
	}

	li {
		border-bottom: 1px solid $grayLighter;

		a {
			@extend .arrow-link;
			display: block !important;
			padding: 1.5rem 3rem 1.5rem 0 !important;
		}
	}

	&.two-column {@extend .two-column-list;}
}

.check-list {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		border-bottom: .5px solid #BCC2C8;
		break-inside: avoid-column;
		margin-top: -1px; // For border collapse effect
		padding: 1rem .75rem 1rem 2rem;
		position: relative;

		&:last-child {border-bottom: none;}

		&:before {
			content: url('#{$libraryPath}/icons/checkmark.svg');
			height: 1.4rem;
			position: absolute;
				top: 1rem;
				left: 0;
			width: 1.4rem;
		}
	}
    
    &.teal {
        li {
            border-top: 0;
            border-bottom: 0;
			color: $textColor;
            
            &::before {
                content: url('#{$libraryPath}/icons/checkmark-teal.svg');
            }
        }
    }

    &.red {
        li {
            border-top: 0;
            border-bottom: 0;
            padding-left: 2.5rem;
            
            &::before {
                content: url('#{$libraryPath}/icons/checkmark-red.svg');
            }
        }
    }
	
    &.dark {
        li {
            border: none;
            padding: 1rem .75rem 1rem 3rem;
            
            &:before {
                background-image: url('#{$libraryPath}/icons/checkmark-dark.svg');
                background-position: center;
                content: '';
                height: 2rem;
                position: absolute;
                    top: 0.75rem;
                    left: 0;
                width: 2rem;
            }
        }    
    }
    
    &.gradient {
        li {
            border-top: 0;
            border-bottom: 0;
            padding-left: 2.5rem;
            
            &::before {
                content: url('#{$libraryPath}/icons/checkmark-gradient.svg');
            }
        }    
    }

	&.two-column {
		@include media($screen-sm) {
			columns: 2;
			column-gap: 2rem;
		}
	}
    
    &.three-column {
        @include media($screen-sm) {
            columns: 3;
            column-gap: 2rem;
        }
    }
    
    &.four-column {
        @include media($screen-sm) {
            columns: 4;
            column-gap: 2rem;
        }
    }
}

.bg-dark {
	.check-list.teal li {
		color: $white;
	}
	
	.big-number-list li:before {
		color: rgba($white, .1);
	}
}

.big-number-list {
	font-size: 1.25rem;
	list-style: none;
   	counter-reset: item;
	margin: 0;
	padding: 0;

	li {
		counter-increment: item;
		margin-bottom: 3rem;
		max-width: 26rem;
		padding-left: 3rem;
		position: relative;

		&:last-child {margin-bottom: 0;}

		&:before {
			content: counter(item);
			color: rgba($black, .1);
			font-size: 2rem;
			font-weight: 900;
			position: absolute;
				top: 0;
				left: 0;
		}
	}

	@include media($screen-sm) {
		font-size: 1.5rem;

		li {
			margin-bottom: 4.5rem;
			padding-left: 4.5rem;

			&:before {
				font-size: 5rem;
				line-height: 1;
			}
		}
	}
}

.gradient-hr {
	background: linear-gradient( to right, $teal, $coral );
	border: none;
	height: 1px;
	margin: $spacing auto;
	width: 6rem;
}

.two-columns,
.flex-blocks {
	hr {
		background: linear-gradient( to right, $teal, $coral );
		border: none;
		height: 1px;
		margin: $spacing auto $spacing 0;
		width: 6rem;
	}
}

.blue-hr {
    border-top: 0;
    border-right: 0;
    border-bottom: 3px solid #78AEFC;
    border-left: 0;
    margin: 0 auto;
    width: 5rem;
}

.white-hr {
    border-top: 0;
    border-right: 0;
    border-bottom: 3px solid $white;
    border-left: 0;
    margin: 0 auto;
    width: 5rem;
}

.percent-increase {
    color: #7acbc6;
    font-size: 2.5rem;
    font-weight: 700;
    padding-right: 2rem;
    position: relative;
}

.percent-increase::after {
    background-image: url('/icons/arrow-up-teal.svg');
    background-repeat: no-repeat;
    background-size: 75%;
    content: '';
    height: 2rem;
    position: absolute;
        right: 0;
        bottom: 0.4rem;
    width: 1rem;
}