/**
 * Theme Buttons
 * DBS>Interactive
 */

/**
 * 	Button color variable
 *	$grayDarkest: #2d2d2d; —— See _vars.scss
 */
 $buttonColor: $teal;
/**
 *
 *	CSS Classes (or how to apply the mixin declared above)
 *   	Overview:
 *  		There is a .button class
 *  		There is a modifying .button--ghost class
 *  			.button--ghost builds on .button by being included after the button class and applying a few overrides to the .button styling.
 *
 *  			HTML Example:
 *  			<a class="button button--ghost" href="#">Example Button</a>
 *
 *  	Additional styles can be added on a per project basis. When possible, use the .button--ghost class as an example
 *  	and build off of the .button class styles; avoid creating a separate class and using @include button(), for this
 *  	will cause (potential) unnecessary CSS bloat.
 */

/**
 *  Button Styling
 */
.button {
	@include button ($blueDarkest, $buttonColor);

	&.arrow {
		padding-right: 3.5rem;
		position: relative;

		&:before,
		&:after {
			content: '';
			background-repeat: no-repeat;
			background-size: contain;
			height: 1.25rem;
			width: 1.25rem;
			position: absolute;
				top: 50%;
				right: 1.25rem;
			transform: translate(0, -50%);
			transition: .25s ease-in-out opacity, .25s ease-in-out transform;
		}

		&:before {
			background-image: url('#{$libraryPath}/icons/arrow.svg');
			opacity: 1;
		}

		&:after {
			background-image: url('#{$libraryPath}/icons/arrow-teal.svg');
			opacity: 0;
		}

		&:hover,
		&:focus {
			&:before,
			&:after { transform: translate(.5rem, -50%); }

			&:before {opacity: 0;}
			&:after {opacity: 1;}
		}
	}

	&.dark {
		background: $blueDarkest;
		border-color: $blueDarkest;
		color: $white;

		&:before {
			background-image: url('#{$libraryPath}/icons/arrow-white.svg');
		}

		&:after {
			background-image: url('#{$libraryPath}/icons/arrow.svg');
		}

		&:focus,
		&:hover {
			background: white;
			color: $blueDarkest;
		}
	}

	/**
	 *  Ghost Button Styling
	 */
	&.ghost {
		background-color: transparent;
		color: $textColor;

		&:hover, &:active, &:focus {
			background-color: $buttonColor;
			color: $textColor;
		}
	}
}

/**
 *	Button styling on a dark background
 */
.bg-dark:not(.bg-white) .button:not(.gform_button) {
	@include button ($blueDarkest, $white);

	&.ghost {
		background-color: transparent;
		color: $white;

		&:hover, &:active, &:focus {
			background-color: $white;
			color: $black;
		}
	}

	&.arrow {
		padding-right: 3.5rem;

		&:after {
			background: url('#{$libraryPath}/icons/arrow.svg');
				background-repeat: no-repeat;
				background-size: contain;
		}

		&:before {opacity: 0;}
		&:after {opacity: 1;}

		&:hover,
		&:focus {
			&:before {opacity: 1;}
			&:after {opacity: 0;}
		}
	}
}

/**
 *	Inline (Arrow) Button Styling
 */
.arrow-link,
.button--inline { // Leaving that old class there for legacy compatibility
	color: $textColor;
	display: inline-block;
	padding-right: 2rem;
	position: relative;
	text-decoration: none;

	&:after {
		background: url('#{$libraryPath}/icons/arrow.svg') no-repeat center;
			background-size: contain;
		content: '';
		display: inline-block;
		height: 1.25rem;
		position: absolute;
			top: 50%;
			right: -0;
		transform: translate(0,-50%);
		transition: transform .25s ease-in-out;
		width: 1.25rem;
	}
	
	&:hover, &:focus {
		color: $textColor;

		&:after {transform: translate(.25em, -50%);}
	}

	&.back {
		padding-left: 2rem;

		&:after {
			left: 0;
			right: auto;
			transform: translate(0,-50%) rotate(180deg);
		}

		&:hover,
		&:focus {
			&:after { transform: translate(-.5rem,-50%) rotate(180deg); }
		}
	}

	// Puts box behind arrow
	&.boxed {
		display: inline-block;
		padding-right: 4rem;

		&:after {
			@include size(3rem);
			background-color: $teal !important;
			background-image: url('#{$libraryPath}/icons/arrow.svg') !important;
			background-size: 1.25rem !important;
			border: 1px solid $teal;
		}

		&.ghost {
			&:after {
				background-color: transparent !important;
				background-image: url('#{$libraryPath}/icons/arrow-teal.svg') !important;
			}
		}
	}

	&.teal {
		&:after { background: url('#{$libraryPath}/icons/arrow-teal.svg') no-repeat center !important; }
	}

	&.teal-dark {
		&:after { background: url('#{$libraryPath}/icons/arrow-teal-dark.svg') no-repeat center !important; }
	}

	&.white {
		&:after { background: url('#{$libraryPath}/icons/arrow-white.svg') no-repeat center !important; }
	}

	&.light-blue {
		&:after { background: url('#{$libraryPath}/icons/arrow-light-blue.svg') no-repeat center !important; }
	}
}

// To apply gradient-border to a link:
// 1. This must be applied to the div around it.
// 2. The link must also have the 'arrow-link' and 'boxed' classes.
.gradient-border {
    position: relative;
    width: 275px;
    
    .arrow-link.boxed {
        padding: 2.9rem 4rem 2.9rem 1.5rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 900;
        width: calc(100% - 1.25rem);

        &::after {
            height: 5.125rem !important;
            width: 5.125rem !important;
        }
    }

    &::before {
        top: 0;
        left: 0;
    }

    &::after {
        bottom: 0;
        right: 0;
        transform: rotate(180deg);
    }

    &::before,
    &::after {
        content: url('/images/partial-gradient-border.svg');
        display: inline-block;
        height: 80px;
        pointer-events: none;
        position: absolute;
        width: 256px;
    }
}

.bg-dark .button--inline,
.bg-dark .arrow-link {
	color: $white;
	&:hover, &:focus {
		color: $white;
	}
	&:after {
		background: url('#{$libraryPath}/icons/arrow-white.svg') no-repeat center;
	}

	&:before {
		background-color: #226066;
	}
}

/**
 * Animated Box w/Arrow Link
 */
.button--box-arrow {
	@extend .button--inline;
	color: $blueDarkest;
	font-weight: 900;
	letter-spacing: 2px;
	padding: $spacing 3.5rem $spacing $spacing;
	position: relative;
	text-decoration: none;
	text-transform: uppercase;
	transform-style: preserve-3d; // Allows pseudo element background behind text without losing it on colored backgrounds;

	// Teal background
	&:before {
		background: $teal;
		content: '';
		height: 100%;
		position: absolute;
			top: 0;
			left: 0;
		transform: scaleX(.33);
		transform-origin: left;
		transition: .25s ease-in-out;
		width: 100%;
		z-index: -1;
	}

	// Arrow
	&:after {right: 1.25rem;}

	&:focus,
	&:hover {&:before { transform: scaleX(1); }}
}

.bg-dark .button--box-arrow,
.button--box-arrow.light {
	color: $teal !important;

	&:before {background: $blue;}
	&:after {background: url('#{$libraryPath}/icons/arrow-teal.svg') no-repeat center;}
}


.bg-white .button--box-arrow {
	color: #050c1a !important;
	
	&::before {background: #7acbc6;}
	
	&::after {background-image: url('/icons/arrow.svg');}
}


// Removes browser button styling
.non-button {
	-webkit-appearance: none;
	appearance: none;
	background: 0;
	border: none;
}

/**
 * Gradient Bordered 
 */
.border-button {
	@extend .button--inline;
	@extend .boxed;
	font-weight: 900;
	letter-spacing: 2px;
	text-transform: uppercase;

	&__border {
		pointer-events: none;
		position: absolute;
		transition: .25s ease-in-out transform;

		&:nth-child(odd) {
			height: calc(100% + 1em);
			transform: scale3d(1, 1.75, 1);
			width: 2px;
		}

		&:nth-child(even) {
			height: 2px;
			transform: scale3d(.5, 1, 1);
			width: calc(100% + 1em);
		}

		&:nth-child(1) {
			background: linear-gradient( to bottom, #8FBFB8, rgba(26, 49, 62, .2) );
			top: -1em;
			left: -1em;
			transform-origin: top;
		}

		&:nth-child(2) {
			background: linear-gradient( to right, #8FBFB8, #F18977 );
			top: -1em;
			left: -1em;
			transform-origin: left;
		}

		&:nth-child(3) {
			background: linear-gradient( to top, #8FBFB8,rgba(26, 49, 62, .2) );
			bottom: -1em;
			right: -1em;
			transform-origin: bottom;
		}

		&:nth-child(4) {
			background: linear-gradient( to left, #8FBFB8, #F18977 );
			right: -1em;
			bottom: -1em;
			transform-origin: right;
		}
	}

	&:hover,
	&:focus {
		color: lighten($teal, 10%);

		.button__border {
			transform: scale3d(1, 1, 1) !important;
		}
	}
}