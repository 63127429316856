/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * 	https://www.fontsquirrel.com/tools/webfont-generator
 *
 * 	Download the resulting files and copy the eot, svg, ttf, woff, and woff2
 * 	files into the appropriate directory.
 *
 * 	Make sure that the font-weight and font-style match the appropriate font.
 */

 @font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-ExtraLight.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-ExtraLight.woff') format('woff');
	font-display: swap;
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-Light.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-Light.woff') format('woff');
	font-display: swap;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-Regular.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-Regular.woff') format('woff');
	font-display: swap;
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-SemiBold.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-SemiBold.woff') format('woff');
	font-display: swap;
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-Bold.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-Bold.woff') format('woff');
	font-display: swap;
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('/fonts/nunito-sans/NunitoSans-Black.woff2') format('woff2'),
		 url('/fonts/nunito-sans/NunitoSans-Black.woff') format('woff');
	font-display: swap;
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
		 url('/fonts/montserrat/Montserrat-Black.woff') format('woff');
	font-display: swap;
	font-weight: 900;
	font-style: normal;
}
