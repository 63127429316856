/**
 * Footer styles
 * DBS>Interactive
 *
 * Classname Structure
 *
 * The rational was to avoid using elements in the stylesheet and to limit nesting.
 *
 * 		site-footer - the footer element
 *
 * 			site-footer__navigation - footer menu
 * 				footer-menu__item - footer menu item
 *
 * 			site-footer__credits - copyright info container
 *
 * 			site-footer__copyright - copyright statement
 *
 */

 .site-footer {
	background-color: $grayDarkest;
	border-top: 1px solid rgba($white, .3);
	content-visibility: auto;
	overflow: hidden;

	&, h1, h2, h3, h4, h5, h6 { color: $white; }

	&__top,
	&__mid,
	&__bottom {
		padding: 2rem 0;

		.contain {
			display: flex;
				flex-direction: column;
			padding: 0 $spacing;
		}
	}

	&__mid {
		border-top: 1px solid rgba($white, .15);
		border-bottom: 1px solid rgba($white, .15);

		.button--inline {
			margin-bottom: $spacing;
		}
	}

	// Resets
	ul { list-style: none; margin: 0; padding: 0; }

	// Default link styles
	a {
		color: $white;
		text-decoration: none;
	}

	.eyebrow { margin-bottom: .75rem; }

	.site-footer__logo svg {
		display: block;
		width: 12em;
	}

	&__navigation {
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		.footer-menu__item {
			margin-right: 2rem;

			a {
				color: $teal;
				font-weight: 900;
				letter-spacing: $spaced;

				span {background: $grayDarkest;}
			}

			&:last-child {margin-right: 0;}
		}

		li { display: inline-block; }

	}

	&__bottom {
		font-size: .9rem;

		span,
		.legal-footer { opacity: .75; }

		.legal-footer {
			display: inline-block;

			ul, li { display: inline-block; }

			a {
				font-weight: normal;
				text-decoration: underline;
			}

			.legal-menu__item {
				border-right: 1px solid $white;
				padding: 0 .6rem 0 .5rem;

				&:last-child {border: none;}
			}
		}
	}

	&__locations {
		.site-footer__address {
			font-size: 1.25rem;

			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				border-right: 1px solid rgba($white, .15);
				margin-right: 1.35rem;
				padding-right: 1.25rem;
			}
		}
	}

	/**
	 * Mobile Styles
	 */
	@include media($screen-sm-max, 'max') {
		&__top {
			.button--inline {margin: 2rem 0 1rem;}
		}

		&__form {
			border-left: 1px solid $teal;
			padding-left: $spacing;
			margin: 0 auto;
			max-width: 25rem;		
		}

		.site-footer__logo {
			margin-bottom: 2rem;
			padding-bottom: 2rem;
			position: relative;

			&:after {
				border-bottom: 1px solid rgba($white, .15);
				content: '';
				position: absolute;
					bottom: 0;
					left: -$spacing;
				width: calc(100% + 3rem);
			}

			img {margin: 0 auto;}
		}

		&__mid {
			&-left {
				text-align: center;

				.eyebrow {
					display: inline-block;
					margin: 0 1rem 0 0;
				}
			}
		}

		.footer-menu {
			display: flex;
				flex-direction: column;
			margin: 0 -#{$spacing};
			padding: 2rem 0;
			text-align: left;

			&__item {
				border-bottom: 1px solid rgba($white, .15);
				margin: 0;
				position: relative;
				width: 100%;

				a {
					display: block;
					padding: 1rem $spacing;
				}

				&--parent:first-child { border-top: 1px solid rgba($white, .15); }

				&.subitem {
					&:first-child {margin-top: -1rem;}
					&:last-child {border: none;}

					a {
						color: $white;
						font-weight: normal;
						letter-spacing: normal;
						padding: 1rem 0;
						position: relative;
						text-transform: none;

						&:after {
							background: url('#{$libraryPath}/icons/arrow-white.svg') no-repeat center;;
								background-size: contain;
							content: '';
							height: 1rem;
							margin-left: 1rem;
							opacity: .7;
							position: absolute;
								top: 50%;
							transform: translateY(-50%);
							width: 1rem;
						}
					}
				}
			}

			.submenu-toggle {
				height: 3.5em;

				&__arrow {
					color: $teal;
				}

				&.open + .footer-menu__submenu {
					max-height: none;
				}
			}

			&__submenu {
				margin: 0 1.5rem;
				max-height: 0;
				overflow: hidden;
			}
		}


		&__bottom {
			text-align: center;

			.contain {flex-direction: column-reverse;}

			.social-media {
				margin-bottom: $spacing;
				padding: 0 0 $spacing;
				position: relative;

				&:after {
					border-bottom: 1px solid rgba($white, .15);
					content: '';
					position: absolute;
						bottom: 0;
						left: -$spacing;
					width: calc(100% + 3rem);
				}
			}
		}

		&__locations {
			margin: 0 auto;
			max-width: 20rem;
			text-align: center;

			.site-footer__address {
				display: inline-block;
				margin-bottom: .75rem;

				&:nth-child(3) {
					border-right: none;
					padding-right: 0;
				}
			}
		}
	}

	@include media($screen-sm) {
		.contain {
			align-items: center;
			flex-direction: row;
			justify-content: space-between;
		}

		&__form {
			display: flex;
				align-items: center;

			a {
				padding-left: 4rem;

				&:before {
					border-right: 1px solid $teal;
					content: '';
					height: 3.5rem;
					position: absolute;
						top: 50%;
						left: 3rem;
					transform: rotate(-30deg) translateY(-58%);
				}
			}
		}

		&__mid {
			padding: $spacing * 2 0;

			.button--inline {margin-bottom: 2rem;}

			a:not(.button--inline) {
				display: inline-block;
				
				&:after {
					content: '';
					display: block;
					border-bottom: 1px solid $teal;
					transform: scaleX(0);
					transform-origin: 100% 0;
					transition: transform .25s ease-in-out;
				}
	
				&:hover,
				&:focus {
					&:after {
						transform: scaleX(1);
						transform-origin: 0 100%;
					}
				}
			}
		}

		.spacer {
			align-self: stretch;
			border-left: 1px solid rgba($white, .15);
			margin: 0 $spacing;
		}

		.submenu-toggle,
		.footer-menu__submenu {display: none;}

		&__navigation {
			border-bottom: 1px solid rgba($white, .15);
			margin-bottom: 2rem;
			padding-bottom: .75rem;
		}

		.footer-menu {
			display: flex;
				flex-wrap: wrap;

				&__item.toplevel {margin-bottom: .75rem;}
		}

		&__locations {
			.site-footer__address {
				margin-bottom: 1rem;

				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4) {padding-right: 1.5rem;}
			}
		}
	}

	// Reverse normal link behavior
	&__address,
	.telephone {
		background: $grayDarkest; // Weird lighthouse fix for ADA contrast
		
		&:after {
			transform: scaleX(1) !important;
    		transform-origin: 0 100% !important;
		}

		&:hover,
		&:focus {
			&:after {
				transform: scaleX(0) !important;
				transform-origin: 100% 0 !important;
			}
		}
	}
}
