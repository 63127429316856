/**
 * Breadcrumb styles
 * DBS>Interactive
 */

// Hide Yoast auto-injected breadcrumbs since we're manually placing them where they need to go.
.breadcrumbs-wrapper {display: none;}

.breadcrumbs {
	font-weight: 400;
	margin-bottom: $spacing;
	width: 100%;

	a,
	strong {font-weight: 400;}
}
