.social-media {
	list-style: none;
	margin: 0;
	padding: 0;

	&__link {
		display: inline-block;
		margin-right: 1.25rem;

		&:last-child {margin-right: 0;}

		&.twitter {
			a {width: 1.8em;}
		}
	}

	svg path {
		fill: rgba($white, .75);
		transition: .25s ease-in-out;
	}

	a {
		display: block;
		width: 2em;

		&:hover,
		&:focus {
			svg path {fill: $teal;}
		}
	}
}